<script>
import get from 'lodash/get'
import moment from 'moment';
import * as XLSX from "xlsx/xlsx.mjs"

export default {
  props: ['results'],

  data () {
    return {
      dialog: false,

      headerResults: [
        { text: 'Ajuste', value: 'id', align: 'center', sortable: false, width: 90 },
        { text: 'Tipo de medição', value: 'type', width: 100 },
        { text: 'Piquete', value: 'paddock.code', width: 100 },
        { text: 'Captura', value: 'captured_at', width: 95 },
        { text: 'MS ajustada por área', value: 'msAdjustedByArea', align: 'right', width: 180 },
        { text: 'MS ajustada', value: 'msAdjusted', align: 'right', width: 120 },
        { text: 'MS visível', value: 'visible_ms', align: 'right', width: 120 },
        { text: 'Área visível', value: 'visible_area', align: 'right', width: 120 },
        { text: 'Info. perdida', value: 'info_lost', align: 'right', width: 150 },
        { text: 'FDN ajustada', value: 'fdnAdjusted', align: 'right', width: 120 },
        { text: 'FDN', value: 'fdn', align: 'right', width: 100 },
        { text: 'PB ajustada', value: 'pbAdjusted', align: 'right', width: 120 },
        { text: 'PB', value: 'pb', align: 'right', width: 100 },
        { text: 'EM ajustada', value: 'emAdjusted', align: 'right', width: 120 },
        { text: 'EM', value: 'em', align: 'right', width: 100 },
      ],
    }
  },

  computed: {
    satellitesDataMaxMS() {
      return get(this.results, 'satellitesData.satellitesDataMaxMS', [])
    }
  },

  created () {
    this.$on('open', () => this.dialog = true)
  },

  methods: {
    cancel(){
      this.dialog = false
      this.$emit('cancel')
    },

    async exportToExcel() {
      const satelliteData = await this.satellitesDataMaxMS
      const results = []
      for (let i = 0; i < satelliteData.length; i++) {
        const items = satelliteData[i]
        for (let j = 0; j < items.length; j++ ) {
          const data = items[j]
          results.push([
          'Medição ' + (i + 1),
          data.type? data.type : '',
          data.paddock.code,
          moment(data.captured_at).format('DD/MM/YYYY'),
          data.msAdjustedByArea? parseFloat(data.msAdjustedByArea.toFixed(2)) : '',
          data.msAdjusted? parseFloat(data.msAdjusted.toFixed(2)) : '',
          data.visible_ms? data.visible_ms : '',
          data.visible_area? data.visible_area : '',
          data.info_lost? data.info_lost : '',
          data.fdnAdjusted? parseFloat(data.fdnAdjusted.toFixed(2)) : '',
          data.fdn? parseFloat(data.fdn.toFixed(2)) : '',
          data.pbAdjusted? parseFloat(data.pbAdjusted.toFixed(2)) : '',
          data.pb? parseFloat(data.pb.toFixed(2)) : '',
          data.emAdjusted? parseFloat(data.emAdjusted.toFixed(2)) : '',
          data.em? parseFloat(data.em.toFixed(2)) : ''
          ])
        }
      }
      var file = "dados.xlsx";
      var workSheet = XLSX.utils.json_to_sheet([]);
      const header = [['Medição','Tipo de medição','Piquete','Captura','MS ajustada por área','MS ajustada',
      'MS visível','Área visível','Info. perdida','FDN ajustada','FDN','PB ajustada','PB','EM ajustada','EM']]
      XLSX.utils.sheet_add_aoa(workSheet, header);
      var workBook = XLSX.utils.book_new();
      XLSX.utils.sheet_add_json(workSheet, results, { origin: 'A2', skipHeader: true });
      XLSX.utils.book_append_sheet(workBook, workSheet, "myWorkSheet");
      XLSX.writeFile(workBook, file);
    }
  }
}
</script>

<template>
  <v-dialog v-model="dialog" max-width="1800">
    <v-card>
      <v-card-title class="text-h5">
        Dados utilizados nos cálculos
        <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn text icon color="green darken-3" @click="exportToExcel()" v-on="on">
            <v-icon>mdi-microsoft-excel</v-icon>
          </v-btn>
        </template>
        <span>Exportar Medições Utilizadas no Cálculo</span>
      </v-tooltip>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="results" class="pt-5">
        <div v-for="(items, index) in satellitesDataMaxMS" :key="items.length + index">
          <hr v-if="index"/>
          <h3 v-if="satellitesDataMaxMS[0][0].type==='CAMPO' && index === 0" class="mb-3 mt-3">Medição de campo</h3>
          <h3 v-else class="mb-3 mt-3">Medição {{ index + 1 }}</h3>
          <v-data-table
            :headers="headerResults"
            :items="items"
            :sort-by="'msAdjustedByArea'"
            :sort-desc="true"
            hide-default-footer
            dense
            class="elevation-3"
          >
            <template v-slot:[`item.id`]="{ item }">
              <v-btn icon color="primary" target='_blank' :to="`/medicoes/${item.id}`">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              {{ item ? item.type === 'CAMPO'? '3C': item.type === 'OPTICO'? '1O': '2R' : '' }}
            </template>
            <template v-slot:[`item.captured_at`]="{ item }">
              {{ item.captured_at | date('DD/MM/YY') }}
              <!-- <div :style="{width: '100px'}">
                {{ item.captured_at | date('DD/MM/YY HH:mm') }}
              </div> -->
            </template>
            <template v-slot:[`item.fdn`]="{ item }">
              <strong>{{ item.fdn | percent(1) }}</strong>
            </template>
            <template v-slot:[`item.fdnAdjusted`]="{ item }">
              <strong>{{ item.fdnAdjusted | number:decimals(2) }}</strong>
            </template>
            <template v-slot:[`item.pb`]="{ item }">
              <strong>{{ item.pb | percent(1) }}</strong>
            </template>
            <template v-slot:[`item.pbAdjusted`]="{ item }">
              <strong>{{ item.pbAdjusted |number:decimals(2) }}</strong>
            </template>
            <template v-slot:[`item.em`]="{ item }">
              <strong>{{ item.em | number:decimals(0) }}</strong>
            </template>
            <template v-slot:[`item.emAdjusted`]="{ item }">
              <strong>{{ item.emAdjusted | number:decimals(2) }}</strong>
            </template>
            <template v-slot:[`item.msAdjusted`]="{ item }">
              <strong>{{ item.msAdjusted | number:decimals(0) }}</strong>
            </template>
            <template v-slot:[`item.msAdjustedByArea`]="{ item }">
              <strong>{{ item.msAdjustedByArea | number:decimals(0) }}</strong>
            </template>
             <template v-slot:[`item.visible_area`]="{ item }">
              <strong>{{ item.visible_area | number:decimals(2) }}</strong>
            </template>
            <template v-slot:[`item.visible_ms`]="{ item }">
              <strong>{{ item.visible_ms | number:decimals(0) }}</strong>
            </template>
            <template v-slot:[`item.info_lost`]="{ item }">
              <strong>{{ item.info_lost | percent(1) }}</strong>
            </template>
            <!-- <strong>
              <template v-if="['crudeProtein', 'fiberInNeutralDetergent'].indexOf(item.name) > -1">{{ item.value * 100 | percent(1) }}</template>
              <template v-else-if="['availableDryMatter', 'maximumConsumptionPossible'].indexOf(item.name) > -1">{{ item.value | number:decimals(0) }}</template>
              <template v-else-if="['metabolizableEnergy'].indexOf(item.name) > -1">{{ item.value | number:decimals(2) }}</template>
              <template v-else-if="item.name !== 'qtdPaddocks'">{{ item.value | number:decimals(1) }}</template>
              <template v-else>{{ item.value }}</template>
            </strong> -->
          </v-data-table>
          <br/>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
