var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Dados utilizados nos cálculos "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"green darken-3"},on:{"click":function($event){return _vm.exportToExcel()}}},on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}])},[_c('span',[_vm._v("Exportar Medições Utilizadas no Cálculo")])]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.cancel}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.results)?_c('v-card-text',{staticClass:"pt-5"},_vm._l((_vm.satellitesDataMaxMS),function(items,index){return _c('div',{key:items.length + index},[(index)?_c('hr'):_vm._e(),(_vm.satellitesDataMaxMS[0][0].type==='CAMPO' && index === 0)?_c('h3',{staticClass:"mb-3 mt-3"},[_vm._v("Medição de campo")]):_c('h3',{staticClass:"mb-3 mt-3"},[_vm._v("Medição "+_vm._s(index + 1))]),_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headerResults,"items":items,"sort-by":'msAdjustedByArea',"sort-desc":true,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary","target":"_blank","to":("/medicoes/" + (item.id))}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item ? item.type === 'CAMPO'? '3C': item.type === 'OPTICO'? '1O': '2R' : '')+" ")]}},{key:"item.captured_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.captured_at,'DD/MM/YY'))+" ")]}},{key:"item.fdn",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm._f("percent")(item.fdn,1)))])]}},{key:"item.fdnAdjusted",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm._f("number:decimals")(item.fdnAdjusted,2)))])]}},{key:"item.pb",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm._f("percent")(item.pb,1)))])]}},{key:"item.pbAdjusted",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm._f("number:decimals")(item.pbAdjusted,2)))])]}},{key:"item.em",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm._f("number:decimals")(item.em,0)))])]}},{key:"item.emAdjusted",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm._f("number:decimals")(item.emAdjusted,2)))])]}},{key:"item.msAdjusted",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm._f("number:decimals")(item.msAdjusted,0)))])]}},{key:"item.msAdjustedByArea",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm._f("number:decimals")(item.msAdjustedByArea,0)))])]}},{key:"item.visible_area",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm._f("number:decimals")(item.visible_area,2)))])]}},{key:"item.visible_ms",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm._f("number:decimals")(item.visible_ms,0)))])]}},{key:"item.info_lost",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm._f("percent")(item.info_lost,1)))])]}}],null,true)}),_c('br')],1)}),0):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }